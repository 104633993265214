var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-col flex-wrap md:flex-row bg-white overflow-hidden"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"order-4 highlight w-full md:w-1/2"},[_c('div',{staticClass:"relative z-10 pb-8 sm:pb-16 md:max-w-2xl md:w-full md:pb-28 mx-auto"},[_c('div',{staticClass:"mt-0 max-w-screen-xl px-4"},[_c('div',{staticClass:"max-w-screen-xl mx-auto"},[_vm._m(3),_c('div',{staticClass:"mt-6"},[_vm._m(4),_c('p',[_vm._v("See our "),_c('router-link',{attrs:{"to":{ name: 'projects' }}},[_vm._v("projects")]),_vm._v(" and "),_c('router-link',{attrs:{"to":{ name: 'services' }}},[_vm._v("services")]),_vm._v(" pages for more information about what we create and how you can work with us.")],1)])])])])]),_vm._m(5),_vm._m(6)]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-1 md:order-2 w-full md:w-1/2"},[_c('img',{staticClass:"object-cover ml-auto w-full",attrs:{"src":require("../assets/images/top_tree.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-2 md:order-1 w-full md:w-1/2"},[_c('div',{staticClass:"relative z-10 pb-8 sm:pb-16 md:w-full md:pb-28 right-0"},[_c('main',{staticClass:"mt-10 pl-4 sm:mt-12 sm:pl-6 md:mt-12 md:pl-8 xl:mt-8"},[_c('div',{staticClass:"text-left"},[_c('img',{staticClass:"w-1/2 sm:w-1/2 md:w-1/2",attrs:{"src":require("../assets/images/logo.png"),"alt":"Curious Bird logo"}}),_c('p',[_vm._v("Curious Bird collaborates with values-driven organizations to create games, learning, and other interactive experiences focused on meaningful change.")]),_c('p',[_vm._v("We're interested in design that is educational, transformative, socially impactful, and curiosity inspiring.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-3 w-full md:w-1/2"},[_c('img',{staticClass:"object-cover left-0 w-full",attrs:{"src":require("../assets/images/branch_left.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-0"},[_c('p',[_vm._v(" Our clients come to us for: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[_c('img',{staticClass:"w-6",attrs:{"src":require("../assets/images/leaf.png")}})]),_c('div',{staticClass:"ml-4"},[_vm._v(" Learning strategy focused on real world change. ")])])]),_c('li',{staticClass:"mt-6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[_c('img',{staticClass:"w-6",attrs:{"src":require("../assets/images/leaf.png")}})]),_c('div',{staticClass:"ml-4"},[_vm._v(" Game, simulation, and learning design that deeply embeds goals and messages into the user's experience. ")])])]),_c('li',{staticClass:"mt-6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[_c('img',{staticClass:"w-6",attrs:{"src":require("../assets/images/leaf.png")}})]),_c('div',{staticClass:"ml-4"},[_vm._v(" Flexible, optimized development in HTML5 and Unity / C#. ")])])]),_c('li',{staticClass:"mt-6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[_c('img',{staticClass:"w-6",attrs:{"src":require("../assets/images/leaf.png")}})]),_c('div',{staticClass:"ml-4"},[_vm._v(" A curiosity-driven, iterative approach to experience design and development. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-6 w-full"},[_c('div',{staticClass:"mt-10 max-w-screen-xl mx-auto px-4 sm:mt-12 sm:px-6 md:mt-6 md:px-8 xl:mt-28"},[_c('div',{staticClass:"text-left"},[_c('p',[_vm._v("Curious Bird was founded by "),_c('a',{attrs:{"href":"https://www.linkedin.com/in/amandawarner/","target":"_blank"}},[_vm._v("Amanda Warner")]),_vm._v(", whose past collaborations include projects with the WHO, the Bill & Melinda Gates Foundation, Transparency International, the Humanitarian Leadership Academy, Rutgers University, and The Union.")]),_c('p',[_vm._v("Amanda has an M.Ed. and more than two decades of professional experience in learning and game design and development. Her superpowers include thoughtful listening, playful exploration, and a willingness to step outside of conventions to create innovative options and solutions.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-5 w-full"},[_c('img',{staticClass:"object-cover left-0 md:w-2/3 mx-auto w-full",attrs:{"src":require("../assets/images/swallows.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col flex-wrap md:flex-row bg-white overflow-hidden"},[_c('div',{staticClass:"order-1 md:w-1/4 w-0"}),_c('div',{staticClass:"flex-grow order-2 w-full md:w-3/4 mt-8"},[_c('img',{staticClass:"object-cover right-0 w-full mt:auto",attrs:{"src":require("../assets/images/branch_right.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col flex-wrap md:flex-row bg-white h-full overflow-hidden"},[_c('div',{staticClass:"flex-grow order-2 md:order-1 w-full lg:w-1/3 md:w-1/2 h-full bottom-0 mt-auto"},[_c('img',{staticClass:"object-cover bottom-0 w-full mt:auto",attrs:{"src":require("../assets/images/boulder_with_laptop.png"),"alt":""}})]),_c('div',{staticClass:"order-1 md:order-2 w-full lg:w-2/3 md:w-1/2 h-full mt-auto"},[_c('div',{staticClass:"pb-4 md:w-full h-full"},[_c('div',{staticClass:"mt-0 px-4"},[_c('div',{staticClass:"max-w-screen-xl mx-auto"},[_c('div',{staticClass:"text-left mb-6"},[_c('p',[_vm._v("Curious Bird is based in Stockholm, Sweden. We collaborate with organizations and individuals around the world.")]),_c('p',[_vm._v("You can reach us at "),_c('a',{attrs:{"href":"mailto:chirp@curiousbird.se"}},[_vm._v("chirp@curiousbird.se")])])])])])])])])
}]

export { render, staticRenderFns }