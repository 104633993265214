<template>
  <div>
    <div class="flex flex-col flex-wrap md:flex-row bg-white overflow-hidden">
      <div class="order-1 md:order-2 w-full md:w-1/2">
        <img class="object-cover ml-auto w-full" src="../assets/images/top_tree.png" alt="">
      </div>
      <div class="order-2 md:order-1 w-full md:w-1/2">
        <div class="relative z-10 pb-8 sm:pb-16 md:w-full md:pb-28 right-0">
          <main class="mt-10 pl-4 sm:mt-12 sm:pl-6 md:mt-12 md:pl-8 xl:mt-8">
            <div class=" text-left">
              <img src="../assets/images/logo.png" class="w-1/2 sm:w-1/2 md:w-1/2" alt="Curious Bird logo">
              <p>Curious Bird collaborates with values-driven organizations to create games, learning, and other interactive experiences focused on meaningful change.</p>
              <p>We're interested in design that is educational, transformative, socially impactful, and curiosity inspiring.</p>
            </div>
          </main>
        </div>
      </div>
      <div class="order-3 w-full md:w-1/2">
        <img class="object-cover left-0 w-full" src="../assets/images/branch_left.png" alt="">
      </div>
    <div class="order-4 highlight w-full md:w-1/2 ">
    <div class="relative z-10 pb-8 sm:pb-16 md:max-w-2xl md:w-full md:pb-28 mx-auto">
      <div class="mt-0 max-w-screen-xl px-4">
      <div class="max-w-screen-xl mx-auto">
        <div class=" mt-0">
          <p>
            Our clients come to us for:
          </p>
        </div>

        <div class="mt-6">
          <ul>
            <li>
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  Learning strategy focused on real world change.
                </div>
              </div>
            </li>
            <li class="mt-6">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  Game, simulation, and learning design that deeply embeds goals and messages into the user's experience.
                </div>
              </div>
            </li>
            <li class="mt-6">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  Flexible, optimized development in HTML5 and Unity / C#.
                </div>
              </div>
            </li>

            <li class="mt-6">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  A curiosity-driven, iterative approach to experience design and development.
                </div>
              </div>
            </li>
          </ul>
          <p>See our <router-link :to="{ name: 'projects' }">projects</router-link> and <router-link :to="{ name: 'services' }">services</router-link> pages for more information about what we create and how you can work with us.</p>
        </div>
      </div>
    </div>
  </div>
    </div>
    <div class="order-6 w-full  ">
      <div class="mt-10 max-w-screen-xl mx-auto px-4 sm:mt-12 sm:px-6 md:mt-6 md:px-8 xl:mt-28">
        <div class=" text-left">
          <p>Curious Bird was founded by <a href="https://www.linkedin.com/in/amandawarner/" target="_blank">Amanda Warner</a>, whose past collaborations include projects with the WHO, the Bill & Melinda Gates Foundation, Transparency International, the Humanitarian Leadership Academy, Rutgers University, and The Union.</p>
          <p>Amanda has an M.Ed. and more than two decades of professional experience in learning and game design and development. Her superpowers include thoughtful listening, playful exploration, and a willingness to step outside of conventions to create innovative options and solutions.</p>
        </div>
      </div>
    </div>
    <div class="order-5 w-full ">
      <img class="object-cover left-0 md:w-2/3 mx-auto w-full" src="../assets/images/swallows.png" alt="">
    </div>
    </div>
  <div class="flex flex-col flex-wrap md:flex-row bg-white overflow-hidden">
    <div class="order-1 md:w-1/4 w-0"></div>
    <div class="flex-grow order-2 w-full md:w-3/4 mt-8">
      <img class="object-cover right-0 w-full mt:auto" src="../assets/images/branch_right.png" alt="">
  </div>
</div>
  <div class="flex flex-col flex-wrap md:flex-row bg-white h-full overflow-hidden">
      <div class="flex-grow order-2 md:order-1 w-full lg:w-1/3 md:w-1/2 h-full bottom-0 mt-auto">
        <img class="object-cover bottom-0 w-full mt:auto" src="../assets/images/boulder_with_laptop.png" alt="">
      </div>
      <div class="order-1 md:order-2 w-full lg:w-2/3 md:w-1/2 h-full mt-auto">
        <div class="pb-4 md:w-full h-full">
          <div class="mt-0 px-4">
            <div class="max-w-screen-xl mx-auto">
            <div class="text-left mb-6">
              <p>Curious Bird is based in Stockholm, Sweden. We collaborate with organizations and individuals around the world.</p>
              <p>You can reach us at <a href="mailto:chirp@curiousbird.se">chirp@curiousbird.se</a></p>

            </div>
          </div>
        </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'About',

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .highlight {
    border: 0px solid blue;
  }
  .card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
}
</style>
